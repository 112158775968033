<template>
    <div class="block-list" v-if="!loading">
        <div class="top-block">
            <h1 class="title">
                {{ $t("vigik.title") }}
            </h1>
            <div>
                {{ $t("vigik.config.subtitle") }}.<br />{{
                    $t("vigik.config.subtitle_2")
                }}.
            </div>
        </div>
        <div class="desktopVersion list-display">
            <table class="access-table">
                <thead>
                    <tr>
                        <th class="name">
                            {{ $t("vigik.config.accessType") }}
                        </th>
                        <th class="description">
                            {{ $t("vigik.config.description") }}
                        </th>
                        <th class="access-total">
                            {{ $t("vigik.config.accessNumber") }}
                        </th>
                        <th class="delete">
                            {{ $t("vigik.config.action") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(service, index) in services"
                        :key="`service-table-${index}`"
                    >
                        <td
                            @click="
                                service.name ===
                                $t('vigik.config.createAccessType')
                                    ? createProfil()
                                    : customAccessType(service)
                            "
                        >
                            <div
                                :class="
                                    service.name ===
                                    $t('vigik.config.createAccessType')
                                        ? 'addVigik'
                                        : 'name'
                                "
                            >
                                <i
                                    class="fa fa-plus"
                                    v-if="
                                        service.name ===
                                        $t('vigik.config.createAccessType')
                                    "
                                ></i>
                                <p>{{ service.name }}</p>
                                <div
                                    class="pin-color"
                                    v-if="service.to_update === '1'"
                                ></div>
                            </div>
                        </td>
                        <td
                            @click="
                                service.name ===
                                $t('vigik.config.createAccessType')
                                    ? createProfil()
                                    : customAccessType(service)
                            "
                            class="description"
                        >
                            {{ service.description }}
                        </td>
                        <td
                            class="access-total"
                            @click="
                                service.name ===
                                $t('vigik.config.createAccessType')
                                    ? createProfil()
                                    : null
                            "
                        >
                            <div @click="consultAccessType(service)">
                                <p>{{ service.acces_count }}</p>
                                <i
                                    class="fa fa-eye"
                                    v-if="
                                        service.name !==
                                        $t('vigik.config.createAccessType')
                                    "
                                ></i>
                            </div>
                        </td>
                        <td
                            class="delete"
                            @click="
                                service.name ===
                                $t('vigik.config.createAccessType')
                                    ? createProfil()
                                    : service.to_update === '1'
                                    ? consultUpdate(service)
                                    : null
                            "
                        >
                            <i
                                class="fa fa-trash"
                                :class="
                                    service.acces_count !== '0' ? 'disable' : ''
                                "
                                style="color: #13375a"
                                v-if="
                                    service.vigik === '0' &&
                                    service.to_update === '0'
                                "
                                @click="
                                    service.acces_count === '0'
                                        ? deleteConfirm(service.id)
                                        : null
                                "
                            />
                            <div
                                v-else-if="service.to_update === '1'"
                                class="update-politics"
                            >
                                <p>
                                    {{
                                        $t("global.modal.valid.politicsUpdate")
                                    }}
                                </p>
                                <i class="fa fa-arrow-right"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination
                v-show="pagesCount > 1"
                :pagesCount="pagesCount"
                :page="currentPage"
                v-on:new-current-page="(e) => (this.currentPage = e)"
            >
            </pagination>
        </div>
    </div>
    <div class="block-loader" v-else>
        <loader />
    </div>
</template>

<script>
import router from "@/router"
import {
    deleteAccessType,
    getAccessType,
    updateAccessType,
} from "@/services/intratone/accesstype"

import Pagination from "@/components/basic/Pagination"
import Loader from "@/components/basic/Loader"
import Array from "@/components/basic/Array.vue"

export default {
    name: "ServicesList",
    components: {
        Pagination,
        Loader,
    },
    data() {
        return {
            services: [],
            pagesCount: 0,
            currentPage: 1,
            loading: false,
            accessTypeArray: {
                header: [
                    {
                        name: this.$t("vigik.config.accessType"),
                        size: "1fr",
                    },
                    {
                        name: this.$t("vigik.config.description"),
                        size: "1fr",
                    },
                    {
                        name: this.$t("vigik.config.accessNumber"),
                        size: "0.5fr",
                    },
                    {
                        name: this.$t("vigik.config.action"),
                        size: "0.5fr",
                    },
                ],
                content: [
                    {
                        addVigik: {
                            value: this.$t("vigik.config.createAccessType"),
                            option: {
                                action: "create",
                                icon: {
                                    class: "fa fa-plus",
                                    style: "color: #ef7b1d;",
                                },
                            },
                        },
                    },
                ],
            },
        }
    },
    mounted() {
        this.getData()
        this.updateVigikBreadCrumb()
    },
    watch: {
        currentPage() {
            this.getData()
        },
    },
    methods: {
        async getData(isDelete = false) {
            this.loading = true
            if (isDelete) {
                //if delete the last object on page, go back
                if (this.services.length === 2 && this.currentPage > 1) {
                    this.services = [
                        {
                            name: this.$t("vigik.config.createAccessType"),
                        },
                    ]
                    this.currentPage -= 1
                    return

                    //getData will be called again by watcher on currentPage
                }
            }

            this.services = [
                {
                    name: this.$t("vigik.config.createAccessType"),
                },
            ]

            await getAccessType({
                stats: "acces",
                page: this.currentPage,
            }).then((response) => {
                this.services.push(...response.data.list)

                this.pagesCount = response.data._pages
                this.loading = false
            })
        },
        consultAccessType(data) {
            this.$router.push({
                name: "AccessList",
                params: {
                    id: data.id,
                    vigikName: this.$t("vigik.access.title", {
                        name: data.name,
                    }),
                },
            })
        },

        customAccessType(data) {
            this.$router.push({
                name: "CustomAccessType",
                params: {
                    id: data.id,
                    vigikName: data.name,
                    isVigik: data.vigik,
                },
            })
        },
        createProfil() {
            router.push({
                name: "V3CreateProfil",
            })
        },
        deleteConfirm(id) {
            this.openModal("validation", {
                title: "valid.delete-access-type",
                validateButton: this.$t("button.delete"),
                valid: async () => {
                    await deleteAccessType(id)

                    await this.getData(true)
                },
            })
        },

        consultUpdate(data) {
            this.openModal("validation", {
                title: "valid.politicsUpdate",
                question: this.$t("global.modal.valid.politicsUpdateText"),
                cancelButton: this.$t("button.refuse"),
                valid: async () => {
                    await this.acceptUpdate(data)
                    await this.getData()
                },
                refuse: async () => {
                    await this.cancelUpdate(data)
                    await this.getData()
                },
            })
        },

        async acceptUpdate(data) {
            await deleteAccessType(data.id)
        },

        async cancelUpdate(data) {
            await updateAccessType(data.id, { to_update: "0" })
        },

        updateVigikBreadCrumb() {
            this.$store.commit("breadCrumb/setBreadCrumb", [
                {
                    name: this.$t("navigation.vigik3"),
                    link: "/vigik3",
                    routeName: "Vigik3",
                },
            ])
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (min-width: 768px) {
    .desktopVersion {
        display: flex;
        flex-direction: column;
    }
}

.block-list {
    width: 100vw;
    height: 100%;
    overflow: auto;
    @media all and (max-width: 768px) {
        padding: 0px 0px 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 1em;
        height: 95%;
    }
    @media all and (min-width: 768px) {
        padding: 20px 80px 40px 80px;
    }
    .top-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 20px;

        @media all and (max-width: 768px) {
            gap: 1em;
            padding-right: 20px;
        }
        .title {
            font-family: $font_avenir_black;
            font-size: $veryBig;
            width: 25%;
            @media all and (max-width: 768px) {
                width: 100%;
                text-align: start;
            }
        }
        .top-right-block {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: 75%;
            .create-profil {
                width: 240px;
                margin-left: 20px;

                i {
                    margin-right: 15px;
                }
            }
        }
    }
    .addAccessType {
        width: 100%;
        justify-content: center;

        button {
            width: 100%;
            max-width: 500px;
            padding: 0.7em 0;
            background-color: $orange-neutral;
            border: none;
            border-radius: 10px;
            color: $white;
            font-family: $font-avenir-medium;
            font-size: $normal;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: $orange-light;
            }

            a {
                color: $white;
                text-decoration: none;
                display: flex;
                gap: 0.5em;
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .list-display {
        width: 100%;
        overflow-x: auto;

        @media all and (max-width: 768px) {
            padding-right: 20px;
        }

        .access-table {
            width: 100%;
            max-width: 100vw;
            min-width: 800px;
            overflow-x: auto;
            thead {
                border: none;
                color: $blue-neutral;
                font-family: $font_avenir_heavy;
                tr {
                    border-bottom: solid 1px $grey-neutral;
                    th {
                        font-size: $normal;
                        font-family: $font_avenir_heavy;
                        padding: 1em 0;
                        &.name {
                            width: 30%;
                            padding-right: 2em;
                        }
                        &.description {
                            width: 30%;
                            padding-right: 2em;
                        }
                        &.access-total {
                            width: 20%;
                            padding-right: 2em;
                        }
                        &.delete {
                            width: 20%;
                            min-width: max-content;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: solid 1px $grey-neutral;
                    transition: all 0.2s;

                    &:hover {
                        background-color: $grey-light;
                        transition: all 0.2s;
                        cursor: pointer;
                    }
                    td {
                        font-size: $small;
                        padding: 1em 0;

                        i {
                            font-size: $normal;
                            cursor: pointer;
                        }
                        p {
                            margin: 0;
                        }

                        .addVigik {
                            display: flex;
                            align-items: center;
                            gap: 0.5em;
                            font-family: $font_avenir_heavy;
                            i {
                                font-size: $small;
                            }
                        }

                        .name {
                            padding-right: 2em;
                            max-width: 100px;
                            min-width: max-content;
                            display: flex;
                            align-items: center;
                            gap: 0.5em;
                            position: relative;

                            p {
                                min-width: max-content;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .pin-color {
                                position: absolute;
                                top: -3px;
                                right: 1em;
                                background-color: $orange-neutral;
                                width: 8px;
                                height: 8px;
                                border-radius: 10px;
                            }
                        }

                        &.description {
                            padding-right: 2em;
                            max-width: 100px;
                            width: 30%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &.access-total {
                            max-width: 20%;
                            padding-right: 2em;
                            div {
                                display: flex;
                                align-items: center;
                                gap: 1em;
                            }
                        }

                        &.delete {
                            max-width: 20%;
                            min-width: max-content;
                            &:hover {
                                cursor: default;
                            }

                            .disable {
                                color: $blue-neutral;
                                opacity: 0.5;
                                &:hover {
                                    cursor: default;
                                }
                            }

                            .update-politics {
                                display: flex;
                                align-items: center;
                                gap: 0.5em;
                                text-wrap: nowrap;
                                &:hover {
                                    cursor: pointer;
                                }

                                p {
                                    margin: 0;
                                    font-family: $font_avenir_heavy;
                                    font-size: $small;
                                    color: $orange-neutral;
                                }

                                i {
                                    font-size: $small;
                                    color: $orange-neutral;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.block-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
